import React from 'react';
import MainLayout from '../../layouts/main';
import { Typography } from '@material-ui/core';
import { SEO } from '../../components/SEO';
import PageHead from '../../components/PageHead';
import { DemoButton } from '../../components/DemoButton';
import Section, { Label } from '../../components/Section';
import { Link } from 'gatsby';

export default () => {
  return (
    <MainLayout>
      <div>
        <SEO
          pathname="/product/segmentation/"
          title="Content Segmentation for Affiliate Publishers"
          description="Segment your content to discover which content formats and product categories are responsible for most of your affiliate revenue."
        />
        <PageHead>
          <Typography
            variant="h3"
            gutterBottom
            component="h1"
            style={{
              maxWidth: '630px',
              margin: '0 auto 36px',
              fontWeight: 900
            }}
          >
            Discover which content segments are driving your affiliate revenue
          </Typography>
          <Typography
            variant="body1"
            color="textSecondary"
            style={{ maxWidth: '510px', margin: '24px auto 12px' }}
          >
            Segment your content to discover which content formats and product
            categories are responsible for most of your affiliate revenue, and
            inform your future content strategy.
          </Typography>
          <img
            src="/images/features/new-segmentation.png"
            style={{
              width: '80%',
              maxWidth: '700px',
              borderRadius: '8px',
              boxShadow: '0px 0px 30px rgba(0, 0, 0, 0.1)',
              transform: 'rotate(-2deg)',
              margin: '48px auto',
              display: 'block'
            }}
            alt="Affiliate segmentation"
          />
          <div style={{ textAlign: 'center', margin: '48px auto 0' }}>
            <DemoButton variant="contained" />
          </div>
        </PageHead>
        <Section
          image="/images/features/segmentation-overview.png"
          variant="screenshot"
          orientation="right"
          headline={
            <>
              <Label>Custom tags and groups</Label>
              <Typography variant="h5" component="h3" gutterBottom>
                Segment your content by custom tags and groups
              </Typography>
            </>
          }
          body={
            <>
              <Typography paragraph variant="body1">
                Report on your affiliate revenue by custom tags and groups to
                discover which content formats and product categories are
                responsible for most of your affiliate revenue.
              </Typography>
              <Typography paragraph variant="body1">
                Create groups for product categories, content formats,
                templates, campaigns, holidays, experiments, and more. No tech
                changes required.
              </Typography>
            </>
          }
        />
        <Section
          image="/images/features/segmentation-manage-tags.png"
          variant="screenshot"
          orientation="right"
          headline={
            <>
              <Label>Ad hoc and automatic tagging</Label>
              <Typography variant="h5" component="h3" gutterBottom>
                Create tags on the fly, without relying on your CMS data
              </Typography>
            </>
          }
          body={
            <>
              <Typography paragraph variant="body1">
                Not in love with your CMS taxonomy? No problem.
              </Typography>
              <Typography paragraph variant="body1">
                Create custom tags on the fly, without tech or CMS changes.
                Either create tags and apply them to your content manually, or
                import a spreadsheet with your existing tags.
              </Typography>
              <Typography paragraph variant="body1">
                But if you are a fan of your CMS setup, you can also reflect
                those through automatic tags, just like our{' '}
                <Link
                  to="/product/authors/"
                  style={{ borderBottom: '1px solid' }}
                >
                  Author reporting
                </Link>
                .
              </Typography>
            </>
          }
        />
        <Section
          image="/images/features/segmentation-bulk-apply.png"
          variant="screenshot"
          orientation="right"
          headline={
            <>
              <Label>Tag management</Label>
              <Typography variant="h5" component="h3" gutterBottom>
                Search, filter, and bulk apply tags in seconds
              </Typography>
            </>
          }
          body={
            <>
              <Typography paragraph variant="body1">
                Search and filter your content by URL, slug, or tag to quickly
                find the content you want to tag. Then, bulk apply tags to
                dozens, hundreds, or thousands of articles at once.
              </Typography>
              <Typography paragraph variant="body1">
                Removing tags is just as easy.
              </Typography>
            </>
          }
        />
      </div>
    </MainLayout>
  );
};
